<template>
    <div>
        <!--<img src="./welcome-to-mekaverse.png" alt="">-->
        <div class="container">
            <template v-if='!show_detail'>
                <div class="but" id="connect-text" @click="connectMetaMask">CONNECT YOUR WALLET</div>
            </template>
            <template v-else>
                <div class="quantity" id="quantity">Quantity (max 20):
                    <input type="number" id="mintnumber" min="1" max="20" v-model="amount" />
                    <button class="butmax" @click='setMax'>SET MAX</button>
                </div>

                <div class="but" @click='mint'>MINT YOUR DIVINE ANACHRY</div>
            </template>
            <div class="mintprice1" id="mint-text">Sold: 796/1000</div>
            <div class="mintprice">Price per mint is 0.09 ETH
                <br>
                <br>
                1. Connect your wallet with MetaMask
                <br>2. Click on button to mint your DIVINE ANACHRY
            </div>

        </div>

    </div>
</template>
<script>
    import Web3 from 'web3';
    const abi = require('./abi.json')
    export default {
        data() {
            return {
                web3: null,
                price: 0.09,
                amount: 2,
                show_detail: false,
            }
        },
        created() {

        },
        async mounted() {
            let self = this
            let web3Provider;
            if (window.ethereum) {

                web3Provider = window.ethereum;
                try {
                    // 请求用户授权
                    await window.ethereum.enable();
                } catch (error) {
                    // 用户不授权时
                    console.error("User denied account access")
                }
            } else if (window.web3) { // 老版 MetaMask Legacy dapp browsers...
                web3Provider = window.web3.currentProvider;
            } else {
                console.log(3)

                web3Provider = new Web3.providers.HttpProvider('http://localhost:8545');
            }
            web3 = new Web3(web3Provider);
            self.web3 = web3

        },

        methods: {
            connectMetaMask: async function() {
                let self = this
                let connected = await self.isMetaMaskConnected()
                console.log('connect', web3, 'asd', connected)
                if (connected) {
                    await window.ethereum.enable();
                    self.show_detail = true
                }
            },
            isMetaMaskConnected: async () => {
                let accounts = await self.web3.eth.getAccounts();
                return accounts.length > 0;
            },
            updateMetaMaskStatus: async () => {
                self.isMetaMaskConnected().then((connected) => {
                    console.log("connected", connected)
                    let button = document.querySelector('#connect-text');
                    let sold = document.querySelector('#mint-text');
                    if (connected) {
                        button.innerHTML = "METAMASK CONNECTED";
                        sold.innerHTML = "Sold: 796/1000";
                        document.getElementById('mint').style.display = 'inline';
                        document.getElementById('connect').style.display = 'none';
                        document.getElementById('mintnumber').style.display = '';
                        document.getElementById('quantity').style.display = '';
                    }

                });
            },
            sendEth() {
                let self = this
                let givenNumber = self.amount + ""
                console.log(self.web3.utils.toWei(givenNumber, 'ether'))
                self.web3.eth.sendTransaction({
                    from: self.web3.currentProvider.selectedAddress,
                    to: '0xa667DFb863838352D22D382f940Cad1658CC6a32',
                    value: (self.web3.utils.toWei(givenNumber, 'ether') * 0.09),
                });
            },
            async mint() {
                let self = this
                const address = '0x41692D1074BC77De6B996C00DaC09959f4702b92'
                const nft = new web3.eth.Contract(abi, address)
                let account = await web3.eth.getAccounts()
                let price = 0.009;
                let givenNumber = self.amount*price + ""
                let add = account[0]
                web3.eth.defaultAccount = add
                let value = web3.utils.toWei(givenNumber)
                
                
                console.log(nft, web3, add)
                nft.methods.mintNFT(self.amount).send({
                    value: value,
                    from: add
                })
            },
            setMax() {
                let self = this
                self.amount = 20
            },



        }
    }

</script>

<style lange='less'>
    @import './style.less';

</style>
